@use './variables' as *;

@mixin flex($aligin-item, $justify-content) {
  display: flex;
  align-items: $aligin-item;
  justify-content: $justify-content;
}

@mixin overlay {
  background-color: rgba($color: #000000, $alpha: 0.6);
}

@mixin fnt($font, $color: inherit) {
  font: $font;
  color: $color;
}

@mixin scroll() {
  scrollbar-gutter: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: $bg-secondary-disable;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: $bg-secondary-inverse;
  }
  &::-webkit-scrollbar-button {
    height: 4px;
  }
}

@mixin ellipsis($lines: 1) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @if $lines > 1 {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    white-space: initial;
  }
}

@mixin shadow-pop-up() {
  box-shadow:
    0 4px 4px 0 $box-shadow-main,
    0 8px 24px 0 $box-shadow-secondary;
}

@mixin shadow-for-switch() {
  box-shadow: 0px 3px 8px 0px rgba($bg-primary, 0.12), 0px 3px 1px 0px rgba($bg-primary, 0.08);
}

@mixin smooth-appearence($timing: .15s) {
  animation: fadeIn ease-in $timing backwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }
}