.MapEngine {
  width: 100%;
  height: 100%;
  z-index: 1;

  position: absolute;
}

.map-tools {
  display: flex;
  gap: 4px;

  &-left {
    margin: 0 20px;
  }
}
