@use '@workspace/4Z1.uikit.react/src/scss/' as *;

.ol-rotate {
  display: none !important;
}


//TODO избавиться от !important при переходе

.baseAppLayout {
  .headerFtp {
    color: $text-secondary;
    h3 {
      color: $text-primary;
    }
  }

  .mouse-position, .mouse-position2 {
    position: absolute;
    color: $text-primary-inverse;
    background-color: $bg-header;
    border-radius: 4px;
    padding: 4px 8px;
    bottom: 10px;
    right: 10px;
    text-align: center;
    min-width: 150px;

    @include fnt($regular-body, $text-primary)
  }

  .title-ofp{
    background-color: $bg-header !important;
    transform: translateX(-50%);
    padding: 4px 8px !important;
    border-radius: 4px;
    bottom: 10px !important;

    @include fnt($regular-body, $text-primary)
  }

  .flight-list .group-checkbox{
    border: none;
  }

  .ant-input-number-group-addon {
    background-color: $bg-secondary;
    border: 1px solid $border-secondary;
    color: $text-primary;
  }

  .ant-input-number {
    border: 1px solid $border-secondary !important;
  }
  .ant-input-number-disabled {
    background-color: inherit;
  }

  .sidebarDemoTitle {
    @include fnt($regular-headline2, $text-primary);
    margin-bottom: 12px;

    a {
      color: $text-accent;
    }
  }

  .panel-top {
    margin-right: 132px;
    display: flex;
    gap: 4px;
    align-items: center;

    .advanced-buttons{
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .ant-btn {
      svg {
        fill: $text-secondary;
      }
    }

    .ant-input {
      border: none;
      height: 32px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .violation-list{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .container___DSGSF{
    position: relative;
    .ant-input{
      height: 40px;
      border-radius: 8px;

      &:focus{
        border: none;
      }
    }

    .searchList{
      position: absolute;
      top: 44px;
      border-radius: 8px;
      width: 100%;
    }

    .ant-input-group-addon{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding-right: 4px !important;

      .ant-input-search-button{
        margin-right: 2px;
      }
    }
  }

  .compare{
    .ant-col-12 {
      &:first-child{
        .panel-top{
          margin-right: 20px !important;
        }
      }
    }
  }

  .ant-input-group-addon {
    background-color: $bg-secondary;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: $bg-secondary-hover;
    border: 1px solid $bg-secondary-hover;
    color: $text-primary;
  }
  .ant-btn-primary:hover {
    background-color: $bg-secondary-hover;
    border: 1px solid $bg-secondary-hover;
    color: $text-primary;
  }
  .ant-btn-primary {
    background-color: $bg-secondary-active !important;
    border: 1px solid $bg-secondary-active !important;
    color: $text-primary;
  }
  .ant-btn-dashed {
    background-color: $bg-secondary-active !important;
    border: 1px solid $bg-secondary-active !important;
    color: $text-primary;
  }
  .ant-btn[disabled] {
    background-color: $bg-secondary-disable !important;
    border: 1px solid $bg-secondary-disable !important;
    color: $text-secondary;

    &:hover {
      color: $text-secondary;
    }
  }

  .ant-typography {
    color: $text-primary;
  }

  .ant-input-affix-wrapper {
    background-color: $bg-secondary;
    border: inherit;
  }
  .ant-input {
    background-color: $bg-secondary;
    border: inherit;
  }

  .ant-input-suffix {
    svg {
      fill: $text-secondary;
    }
  }

  .ol-zoom {
    top: 66px !important;
    right: 8px !important;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &.ol-control {
      background-color: inherit;
    }
    .ol-zoom-in,
    .ol-zoom-out {
      background-color: $bg-primary !important;
      color: $text-primary !important;
      width: 40px;
      height: 40px;

      border-radius: 4px !important;

      &:hover {
        border: none !important;
      }
    }
  }

  .ant-tree {
    background-color: $bg-primary;
    color: $text-primary;

    .ant-tree-node-selected {
      background-color: $bg-secondary-active;
    }
    .ant-tree-node-content-wrapper:hover {
      background-color: $bg-secondary-hover;
    }
  }

  label {
    color: $text-primary;
  }

  .ant-picker {
    background-color: $bg-secondary !important;
    border-radius: 4px !important;
    border: none !important;

    .ant-picker-separator {
      color: $text-secondary;
    }
    .anticon-calendar {
      color: $text-secondary;
    }
  }

  .ant-select-dropdown {
    background-color: $bg-secondary !important;
  }

  .ant-select-selector {
    background-color: $bg-secondary !important;
    border-radius: 4px !important;
    border: 1px solid $border-primary !important;
    color: $text-primary;

    .ant-select-selection-item {
      background-color: transparent !important;
    }
  }

  .group-checkbox {
    background-color: $bg-secondary;
    border: 1px solid $border-primary;
    border-radius: 8px;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: $bg-secondary;
        border-color: $border-primary;
        border-radius: 4px;
      }
    }
    span {
      color: $text-secondary;
    }
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: $bg-accent;
        border-color: $bg-accent;
        border-radius: 4px;
      }
    }
    span {
      color: $text-secondary;
    }
  }

  .headerLeft{
    .ant-picker-range{
      margin-left: 20px;
    }
  }

  .ant-list-bordered {
    border: none;
  }

  .ant-picker-panel-container {
    background: $bg-primary !important;
  }
  .ant-list-items {
    border-radius: 8px;
    border: 1px solid $border-primary;
    background-color: $bg-primary;
    overflow: hidden;
    @include shadow-pop-up();
    .ant-list-item {
      background-color: $bg-secondary;
      border-bottom: 1px solid $border-primary !important;

      a,
      span,
      .ant-list-item-meta-title,
      .ant-list-item-meta-description {
        color: $text-secondary !important;

        &:hover {
          color: $text-primary !important;
        }
      }

      /*
        Временное решение для стилизации модального окна доступа на странице полётов/карточки полёта
        Стили выше перетирают стили модального окна

        Затрагивает только модальное окно доступа
      */
      .access-modal {
        a,
        span {
          color: inherit !important;
        }
      }

      &:hover {
        background-color: $bg-secondary-hover;
      }
    }
  }

  .ant-card-body {
    padding: 12px 0;
  }

  .ant-col {
    color: $text-secondary;
  }

  a.ant-btn,
  .ant-btn-default {
    background-color: $bg-secondary;
    border: 1px solid $border-primary;
    border-radius: 4px;
    color: $text-secondary;

    &:hover {
      background-color: $bg-secondary-hover;
      border: 1px solid $border-primary;
      color: $text-primary;
    }
  }
  .ant-card {
    background: $bg-primary;
    border: none;
    .ant-select-selection-item {
      background-color: $bg-faint;
      border: $border-secondary;
      color: $text-primary;
      .anticon-close {
        svg {
          fill: $text-secondary;
        }

        &:hover {
          svg {
            fill: $text-primary;
          }
        }
      }
    }
    .ant-pro-card {
      background: transparent;
    }
  }

  p {
    color: $text-secondary;
  }
  .ant-switch {
    background-color:  #00000040;
  }
  .ant-switch-checked {
    background-color: $bg-accent;
  }

  .ant-table {
    background-color: $bg-secondary-disable;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    @include fnt($regular-body, $text-primary);

    .ant-table-thead {
      height: 24px;
    }
    th {
      background: $bg-primary;
      max-height: 24px;
      border: 1px solid $bg-secondary !important;

      @include fnt($regular-headline2, $text-primary);
    }

    .ant-table-body {
      @include scroll();
    }

    tr {
      background: $bg-secondary-disable !important;

      strong {
        @include fnt($regular-body, $text-primary);
      }
    }

    td {
      background: transparent !important;
    }

    .ant-table-cell {
      a {
        color: $text-accent;
      }
    }

    .ant-table-cell-row-hover {
      &:hover {
        background: $bg-secondary-hover;
      }
    }
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid $border-secondary;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border: 1px solid $border-secondary;
  }

  .ant-table-cell {
    border-right: 1px solid $border-secondary !important;
    border-bottom: 1px solid $border-secondary;
  }
}
.ant-pro-card-collapsible-icon {
  svg {
    path {
      fill: $icon-primary;
    }
  }
}

// TODO избавиться от этих стилей. Когда Илья нарисует макет для построения маршрута.
.ant-select {
  .ant-select-selector {
    background-color: $bg-secondary !important;
    border: 1px solid $border-primary !important;
    color: $text-secondary !important;
  }

  .ant-select-arrow {
    color: $text-secondary !important;
  }
}

.ant-select-dropdown {
  background-color: $bg-secondary !important;
  border: 1px solid $border-primary !important;
  color: $text-secondary !important;
  @include fnt($regular-caption1);
}

.ant-select-item-option-active {
  background-color: $bg-secondary !important;
  border: 1px solid $border-primary !important;
  color: $text-secondary !important;
}

.ant-select-item {
  background-color: $bg-secondary !important;
  border: 1px solid $border-primary !important;
  color: $text-secondary !important;
}

.ant-picker-range, .ant-select, .group-checkbox {
  margin-bottom: 0!important;
}

.listDemo {
  border-radius: 8px !important;
  border: 1px solid $border-primary !important;
  background-color: $bg-primary;
  overflow: hidden;
  @include shadow-pop-up();

  .ant-list-item {
    background-color: $bg-secondary;
    border-bottom: 1px solid $border-primary !important;

    a {
      color: $text-secondary !important;

      &:hover {
        color: $text-primary !important;
      }
    }

    &:hover {
      background-color: $bg-secondary-hover;
    }
  }

}
.sidebar-content {
  padding: 0px 15px !important;
  background-color: $bg-secondary-disable !important;
  &.sidebarContent{
    top: 10px;
    border-radius: 8px;
    @include shadow-pop-up;

    button{
      background-color: $text-primary;
      border-radius: 4px;
      background-position: center 1px;
    }

    input{
      margin: 0 4px;
      background-color: $bg-secondary;
      border: inherit;
    }
    label{
      @include fnt($regular-subtitle2, $text-primary)
    }
  }
}
.ant-switch-handle{
  &::before{
    background-color: $icon-primary !important;
  }
}
.ant-pro-card {
  background-color: $bg-secondary !important;
}
.ant-pro-card-title {
  color: $text-primary !important;
}
.ant-pro-card-body{
  color: $text-secondary !important;
}
.ant-pro-page-container {
  background-color: $bg-faint !important;
}
.ant-pro-card-divider{
  background-color: $border-primary;
}

.ant-menu-item a{
  color: rgba(255, 255, 255, .5) !important;
  &:hover{
    color: #fff;
  }
}
.ant-menu-vertical{

  .ant-menu-item-active {
    background-color: initial;
    span{
      color: unset !important;
    }
  }
  .ant-menu-submenu-active {
    background-color: inherit;
    span{
      color: unset !important;
    }
  }
  span{
    color: $text-secondary !important;
  }
}

.ant-menu-item-selected{
  background-color: transparent !important;
}


.ant-menu-submenu-title {
  color: unset !important;
}

.ant-menu-submenu-popup {
  background-color: $bg-secondary-hover !important;
  .ant-menu-item-disabled{
    span{
      color: #cecece !important;
    }
  }
}

.ant-empty-description {
  color: $text-secondary;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title.active{
  background-color: transparent;
}

.map-context-menu .coord {
  background: $bg-secondary-hover !important;
  cursor: default;
  text-align: center;
}

.ant-transfer-list {
  border-color: $border-secondary;
  background-color: $bg-secondary !important;
}

.ant-alert {
  border: none !important;
}

.anticon-setting {
  svg {
    path {
      fill: $icon-secondary;
    }
  }
}

.ant-transfer-list-content-item-checked {
  background-color: $bg-secondary-hover !important;
}

.marginless-page-container  {
  background-color: $bg-faint !important;

  .marginless-page-container-children-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .ant-transfer{
    justify-content: space-between;

    .ant-transfer-list{
      width: 100% !important;
      height: 70vh !important;

      .ant-transfer-list-header-title{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .anticon-edit{
          padding: 8px;
          background-color: $bg-primary;
          border-radius: 4px;
        }
      }

      .ant-select{
        width: 100% !important;
        margin-top: 0 !important;
      }
    }
  }
}

.ant-select-selection-item-remove{
  svg {
      fill: $bg-pressed-hover !important;
  }
}
.anticon-check {
  svg {
    fill: $bg-pressed-hover !important;
  }
}

.anticon-close-circle {
  svg {
    fill: $bg-pressed-hover !important;
  }
}

.ant-message-error {
  svg path {
      fill: $icon-critical;
  }
}

.marginless-page-container{
  height: 100%;
  overflow: auto;
  @include scroll();
  padding-bottom: 100px;
}

#info {
  @include shadow-pop-up();
  pointer-events: none;
  color: $text-secondary;
  user-select: none;
  position: absolute;
  background-color: $bg-secondary;
  z-index: 1;
  white-space: nowrap;
  border-radius: 8px;
  margin-top: auto;
  
  &:not(:empty) {
    padding: 6px 10px;
  }
}

/** 
  Скрываем дефолтную кнопку закрытия модального окна
*/
.ol-popup-closer {
  display: none;
}

.ol-popup {
  position: absolute;
  bottom: 12px;
  left: -50px;
  min-width: 150px;
  white-space: nowrap;
  text-align: center;

  /** 
    Отключаем возможность множественного выбора элементов, чтобы избежать некрасивого выделения всего списка
  */
  .ant-list-items {
    user-select: none;
    border-radius: 8px;
    overflow: hidden;
    background-color: $bg-secondary;
    @include shadow-pop-up();
  }

  .ant-list-item {
    padding: 5px 8px;
    background-color: $bg-secondary;
    border: none !important;

    &:hover{
      background-color: $bg-secondary-hover;
    }
    
    a {
      @include fnt($regular-subtitle2, $text-secondary);

      &:hover{
        color: $text-primary;
      }
    }
  }
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: $bg-secondary;
  border-width: 8px;
  left: 48px;
  margin-left: -8px;
}

.ant-page-header{
  padding: 0 16px !important;
}

.ant-page-header-footer{
  margin: 0 !important;
}

.ant-pro-page-container-warp{
  background-color: $bg-header !important;
}

.scrollContainer{
  height: 100%;
  padding-bottom: 60px;
  @include scroll();
  overflow-y: auto;

  .ant-card-bordered{
    border-radius: 12px;
    .ant-card-body{
      padding: 12px;
    }
  }

  .ant-divider-horizontal{
    border-top: 1px solid $bg-primary;
  }

  .ant-picker-range{
    border: 1px solid $border-primary !important;
  }
  .ant-pro-card-body{
    border-radius: 12px
  }

  .ant-table-wrapper{
    margin: 0 !important;
  }
}

.oldTitle{
  padding-left: 16px;
  margin: 0;
}